import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
export default {
  path: '/BAS',
  name: 'bas',
  meta,
  component: BasicLayout,
  children: [{
    path: '/Bas/BasicData/List',
    name: 'Bas/BasicData/List',
    meta: {
      ...meta,
      title: '基础资料管理',
      closable: true
    },
    component: () => import('@/pages/BAS/BasicData/List/index.vue')
  }, {
    path: '/BAS/AuthRule/List',
    name: 'BAS/AuthRule/List',
    meta: {
      ...meta,
      title: '权限规则列表',
      closable: true
    },
    component: () => import('@/pages/BAS/AuthRule/List')
  }, {
    path: '/BAS/AuthorityItem/List',
    name: 'BAS/AuthorityItem/List',
    meta: {
      ...meta,
      title: '权限项列表',
      closable: true
    },
    component: () => import('@/pages/BAS/AuthorityItem/List')
  }, {
    path: '/BAS/Router/List',
    name: 'BAS/Router/List',
    meta: {
      ...meta,
      title: '路由列表',
      closable: true
    },
    component: () => import('@/pages/BAS/Router/List')
  }, {
    path: '/BAS/BusinessObject/List',
    name: 'BAS/BusinessObject/List',
    meta: {
      ...meta,
      title: '业务对象类表',
      closable: true
    },
    component: () => import('@/pages/BAS/BusinessObject/List')
  }, {
    path: '/BAS/AppModule/List',
    name: 'BAS/AppModule/List',
    meta: {
      ...meta,
      title: '模块列表',
      closable: true
    },
    component: () => import('@/pages/BAS/AppModule/List')
  }]
};
import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'FIN-';
export default {
  path: '/FIN',
  name: 'FIN',
  redirect: {
    name: `${pre}VersionReplaceRecord`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: '/FIN/PlatformReceiveBill/List',
    name: 'FIN-PlatformReceiveBill-List',
    meta: {
      ...meta,
      title: '平台收款单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformReceiveBill/List')
  }, {
    path: '/FIN/Settlement/DouYin/List',
    name: 'FIN-Settlement-DouYin-List',
    meta: {
      ...meta,
      title: '抖音结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/DouYin/List')
  }, {
    path: '/FIN/Settlement/DouYinDynamic/List',
    name: 'FIN-Settlement-DouYinDynamic-List',
    meta: {
      ...meta,
      title: '抖音动账单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/DouYinDynamic/List')
  }, {
    path: '/FIN/Settlement/JD/List',
    name: 'FIN-Settlement-JD-List',
    meta: {
      ...meta,
      title: '京东结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/JD/List')
  }, {
    path: '/FIN/Settlement/KuaiShou/List',
    name: 'FIN-Settlement-KuaiShou-List',
    meta: {
      ...meta,
      title: '快手结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/KuaiShou/List')
  }, {
    path: '/FIN/Settlement/KuaiShouSettRefund/List',
    name: 'FIN-Settlement-KuaiShouSettRefund-List',
    meta: {
      ...meta,
      title: '快手结算退款单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/KuaiShouSettRefund/List')
  }, {
    path: '/FIN/Settlement/Lazada/List',
    name: 'FIN-Settlement-Lazada-List',
    meta: {
      ...meta,
      title: 'Lazada结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/Lazada/List')
  }, {
    path: '/FIN/Settlement/Pdd/List',
    name: 'FIN-Settlement-Pdd-List',
    meta: {
      ...meta,
      title: '拼多多结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/Pdd/List')
  }, {
    path: '/FIN/Settlement/Shopee/List',
    name: 'FIN-Settlement-Shopee-List',
    meta: {
      ...meta,
      title: 'Shopee结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/Shopee/List')
  }, {
    path: '/FIN/Settlement/TianMao/List',
    name: 'FIN-Settlement-TianMao-List',
    meta: {
      ...meta,
      title: '天猫结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/TianMao/List')
  }, {
    path: '/FIN/Settlement/Tiktok/List',
    name: 'FIN-Settlement-Tiktok-List',
    meta: {
      ...meta,
      title: 'Tiktok结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/Tiktok/List')
  }, {
    path: '/FIN/Settlement/XiaoHongShu/List',
    name: 'FIN-Settlement-XiaoHongShu-List',
    meta: {
      ...meta,
      title: '小红书结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/XiaoHongShu/List')
  }, {
    path: '/FIN/Settlement/YouZan/List',
    name: 'FIN-Settlement-YouZan-List',
    meta: {
      ...meta,
      title: '有赞结算单',
      closable: true
    },
    component: () => import('@/pages/FIN/PlatformSettlementBill/YouZan/List')
  }]
};
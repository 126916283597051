import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true,
  cache: true,
  FCache: true
};
export default {
  path: '/PLM',
  name: 'plm1',
  meta,
  component: () => import('@/pages/placeholder/index.vue'),
  children: [{
    path: '/PLM/product/uploadDesignDraft',
    name: 'PLM-product-uploadDesignDraft',
    meta: {
      ...meta,
      title: '上传设计稿',
      closable: true,
      overflowVisible: true
    },
    component: () => import('@/pages/PLM/product/manuscript/uploadDesignDraft/index.vue')
  }, {
    path: '/plm/product/develop/editInfo',
    name: 'PLM-product-develop-editInfo',
    meta: {
      ...meta,
      title: '修改组装信息',
      closable: true,
      overflowVisible: true
    },
    component: () => import('@/pages/PLM/product/develop/assemble/index.vue')
  }]
};
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_select = _resolveComponent("a-select");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_a_select, _mergeProps({
    style: {
      "width": "100%"
    },
    value: $setup.value,
    "onUpdate:value": _cache[0] || (_cache[0] = $event => $setup.value = $event),
    options: $setup.tableList,
    fieldNames: $props.fieldNames,
    onSearch: _cache[1] || (_cache[1] = v => $setup.getList(v)),
    onChange: $setup.handleChange
  }, $setup.getProps, {
    "max-tag-count": $setup.maxTagCount
  }), null, 16 /* FULL_PROPS */, ["value", "options", "fieldNames", "max-tag-count"])]);
}
import { ref, toRefs } from "vue";
import ObsClient from "esdk-obs-browserjs";
const env = process.env.NODE_ENV;
const Bucket = 'focallure.obs.01';
const path = env === 'production' ? 'PLM-Test' : 'PLM-Test';

// 创建ObsClient实例
let obsClient = new ObsClient({
  access_key_id: 'ACZTVZO7VZMV0P8HIYKO',
  secret_access_key: 'vp6h2TNrZHt9M4H3HKqgMBesZCeJia1b4ZCe3kAJ',
  server: 'http://obs.cn-south-1.myhuaweicloud.com',
  timeout: 6000
});
export const useObs = () => {
  const resultStatus = ref(0);
  const resultPath = ref('');
  const progressCallbackValue = ref({
    averageRate: 0,
    // 速度，
    percentageValue: 0,
    // 上传的进度百分比
    transferredAmount: 0,
    // 已经上传的值
    totalAmount: 0,
    // 总的值
    estimatedRemainingTime: ''
  });
  const formatSeconds = seconds => {
    let hours = Math.floor(seconds / 3600); // 计算小时数
    let minutes = Math.floor(seconds % 3600 / 60); // 计算分钟数
    let remainingSeconds = seconds % 60; // 计算剩余的秒数

    // 将结果格式化为两位数，不足两位的补零
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');
    let formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    let h = '';
    let m = '';
    let s = '';
    // 返回格式化后的字符串
    if (formattedHours) {
      h = formattedHours + '时';
    }
    if (formattedMinutes) {
      m = formattedMinutes + '分';
    }
    if (formattedSeconds) {
      s = formattedSeconds + '秒';
    }
    return h + m + s;
  };

  // filePath 案例 /scy/obs
  const fileUpload = (files, filePath = '') => {
    let callback = function (transferredAmount, totalAmount, totalSeconds) {
      progressCallbackValue.value.transferredAmount = (transferredAmount / (1024 * 1024)).toFixed(2) + 'MB';
      progressCallbackValue.value.totalAmount = (totalAmount / (1024 * 1024)).toFixed(2) + 'MB';
      progressCallbackValue.value.averageRate = (transferredAmount * 1.0 / totalSeconds / 1024).toFixed(2) + 'KB/S';
      progressCallbackValue.value.percentageValue = parseInt(transferredAmount * 100.0 / totalAmount);

      // 计算剩余时间
      let remainingBytes = totalAmount - transferredAmount;
      let s = parseInt(remainingBytes / Number(transferredAmount * 1.0 / totalSeconds));
      progressCallbackValue.value.estimatedRemainingTime = formatSeconds(s); // 假设传输速度为 1000 字节/秒

      // 获取上传平均速率（KB/S）
      console.log(transferredAmount * 1.0 / totalSeconds / 1024);
      // 获取上传进度百分比
      console.log(transferredAmount * 100.0 / totalAmount);
    };
    resultPath.value = path + filePath + '/' + files.name;
    obsClient.putObject({
      Bucket,
      // 目录
      Key: resultPath.value,
      SourceFile: files,
      ProgressCallback: callback
    }, function (err, result) {
      if (err) {
        resultStatus.value = 404;
        console.error('Error-->' + err);
      } else {
        resultStatus.value = 200;
        console.log('Status-->' + result.CommonMsg.Status);
      }
    });
  };
  const obsDataInit = () => {
    resultStatus.value = 0;
    resultPath.value = '';
    progressCallbackValue.value.averageRate = 0;
    progressCallbackValue.value.percentageValue = 0;
    progressCallbackValue.value.transferredAmount = 0;
    progressCallbackValue.value.totalAmount = 0;
    progressCallbackValue.value.estimatedRemainingTime = '';
  };
  return {
    obsDataInit,
    // 初始化数据
    fileUpload,
    // 上传的方法
    progressCallbackValue,
    // 上传时的返回值
    resultStatus,
    // 上传的结果 404失败， 200成功
    resultPath // 上传保存的路径
  };
};
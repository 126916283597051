import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Table = _resolveComponent("Table");
  return _openBlock(), _createBlock(_component_Table, {
    ref: "refTable",
    height: $props.height,
    columns: $setup._columns,
    loading: $props.loading,
    data: $setup.data,
    onOnRowClick: $setup.onRowClick,
    onOnRowDblclick: $setup.onRowDblClick,
    onOnSelectionChange: $setup.onSelectionChange
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList(_ctx.$slots, (index, name) => {
    return {
      name: name,
      fn: _withCtx(data => [_renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))])
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["height", "columns", "loading", "data"]);
}
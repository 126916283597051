import { mapMutations } from 'vuex';
import { on, off } from 'view-ui-plus/src/utils/dom';
import { setMatchMedia } from 'view-ui-plus/src/utils/assist';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
setMatchMedia();
export default {
  name: 'app',
  methods: {
    zhCN() {
      return zhCN;
    },
    ...mapMutations('admin/layout', ['setDevice', 'setBodyHeight']),
    handleWindowResize() {
      this.handleMatchMedia();
      this.handleSetBodyHeight();
    },
    handleMatchMedia() {
      const matchMedia = window.matchMedia;
      if (matchMedia('(max-width: 600px)').matches) {
        this.setDevice('Mobile');
      } else if (matchMedia('(max-width: 992px)').matches) {
        this.setDevice('Tablet');
      } else {
        this.setDevice('Desktop');
      }
    },
    handleSetBodyHeight() {
      this.setBodyHeight(document.body.offsetHeight);
    }
  },
  mounted() {
    on(window, 'resize', this.handleWindowResize);
    this.handleMatchMedia();
    this.handleSetBodyHeight();
    console.log('当前环境：' + process.env.NODE_ENV);
    console.log('打包时间：' + process.env.VUE_APP_BUILD_TIME);
  },
  beforeUnmount() {
    off(window, 'resize', this.handleWindowResize);
  }
};
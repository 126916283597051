// {
//     path: '/sales',
//     title: '销售管理',
//     children: [
//         {
//             title: "马帮物料替换",
//             children: [
//                 {
//                     path: "/Sales/MaBangVersionReplaceLog/List",
//                     title: '物料版本替换日志'
//                 }
//             ]
//         }
//     ]
// }

import SAL from "./sidebar/SAL";
import EHR from "./sidebar/EHR";
import FIN from "./sidebar/FIN";
import MDM from "./sidebar/MDM";
import SOM from "./sidebar/SOM";
import BAS from "./sidebar/BAS";
import PLM from "./sidebar/PLM";
import Systems from "./sidebar/Systems";
import Monitor from "./sidebar/monitor";

// 1
export default [PLM, SAL,
// EHR,
FIN,
// MDM,
// SOM,
// BAS,
Systems, Monitor];
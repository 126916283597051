import { Get, Post, Put } from "@/api/http.js";
import { ref, toRefs, reactive, watch, provide, onMounted, computed, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'index',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 500
    },
    title: {
      type: String,
      default: "编辑"
    },
    request: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    PrimaryKey: {
      type: String,
      required: true
    }
  },
  emits: ['on-save', 'on-cancel', 'update:modelValue'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const props = __props;
    const {
      request,
      url,
      PrimaryKey
    } = toRefs(props);

    /**
     * 编辑数据处理
     */
    const editId = ref(0);
    const editData = ref({});
    const isSave = ref("Z"); // Z: 默认, A：保存中, B：保存失败, C：保存成功

    provide('isSave', isSave);
    watch(editId, newVal => {
      editData.value = {};
      isSave.value = "Z";
      if (newVal > 0) getData();
    });

    // 获取数据详情
    const getData = () => {
      let replUrl = url.value.replace('PageList', editId.value);
      Get(replUrl).then(res => {
        editData.value = res;
      });
    };

    /**
     * 显示状态处理
     */
    const visibleStatus = ref(false);

    /**
     * 取消
     */
    const onCancel = () => {
      editId.value = 0;
      visibleStatus.value = false;
    };

    /**
     * 保存
     */
    const onSave = () => {
      isSave.value = "A";
    };
    watch(isSave, newVal => {
      if (newVal == "C") {
        visibleStatus.value = false;
        emit('on-save');
      }
    });

    /**
     * 显示状态发生改变
     */
    const onVisibleChange = event => {
      emit('update:modelValue', event);
    };
    let drawerShow = ref(true);
    let drawerWidth = ref(500);
    let formData = reactive({});
    __expose({
      visibleStatus,
      editId,
      isSave
    });
    const __returned__ = {
      props,
      request,
      url,
      PrimaryKey,
      editId,
      editData,
      isSave,
      getData,
      emit,
      visibleStatus,
      onCancel,
      onSave,
      onVisibleChange,
      get drawerShow() {
        return drawerShow;
      },
      set drawerShow(v) {
        drawerShow = v;
      },
      get drawerWidth() {
        return drawerWidth;
      },
      set drawerWidth(v) {
        drawerWidth = v;
      },
      get formData() {
        return formData;
      },
      set formData(v) {
        formData = v;
      },
      get Get() {
        return Get;
      },
      get Post() {
        return Post;
      },
      get Put() {
        return Put;
      },
      ref,
      toRefs,
      reactive,
      watch,
      provide,
      onMounted,
      computed,
      nextTick,
      get useRoute() {
        return useRoute;
      },
      get useRouter() {
        return useRouter;
      },
      get useStore() {
        return useStore;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
import Import from "../pages/FIN/WriteOff/receivable/hand/import.vue";
const BasicLayout = () => import('@/layouts/basic-layout');
// 系统管理
const SysDepartmentList = () => import('@/pages/BAS/Department/List'); // 部门管理
const SysMenuList = () => import('@/pages/BAS/Menu/List'); // 菜单管理
const SysUserList = () => import('@/pages/BAS/User/List'); // 用户管理
const SysRoleList = () => import('@/pages/BAS/Role/index.vue'); // 角色管理
const Log = () => import('@/pages/BAS/log/index.vue'); // log日志

const MonitorPlatformStock = () => import('@/pages/monitor/platform/stock'); // 库存监控 + name
const MonitorPlatformShip = () => import('@/pages/monitor/platform/ship/index.vue'); // 发货差异监控 + name
const MonitorPlatformShipFlowPath = () => import('@/pages/monitor/platform/shipFlowPath/index.vue'); // 发货全流程监控 + name
const MonitorPlatformTransmission = () => import('@/pages/monitor/platform/transmission/index.vue'); // 店铺订单传输马帮监控

const MonitorPlatformOrder = () => import('@/pages/monitor/platform/order/index.vue'); // 订单监控 + name

const FINWriteOffFeeCertificate = () => import('@/pages/FIN/WriteOff/feeCertificate/index.vue'); // 费用凭证列表
const FINWriteOffBillList = () => import('@/pages/FIN/WriteOff/billList/index.vue'); // 上传结算单列表

const PlatformStoreList = () => import('@/pages/MDM/PlatformStore/List/index.vue');

// 产品开发
const PlmProductMaterials = () => import('@/pages/PLM/product/materials/index.vue'); // 物料管理 + name
const PlmProductManuscript = () => import('@/pages/PLM/product/manuscript/index.vue'); // 稿件管理 + name
const PlmProductManuscriptDetails = () => import('@/pages/PLM/product/manuscript/details/index.vue'); // 稿件详情 + name

const PlmProductDevelop = () => import('@/pages/PLM/product/develop/index.vue'); // 开发管理
const PlmProductDevelopDel = () => import('@/pages/PLM/product/develop/details/index.vue'); // 开发管理 - sku详情
const PlmProductDevelopCreate = () => import('@/pages/PLM/product/develop/create/index.vue'); // 建款
const PlmProductDevelopAddLand = () => import('@/pages/PLM/product/develop/create/addLan.vue'); // 加语言版本
const PlmProductDevelopNewQuality = () => import('@/pages/PLM/product/newQuality/index.vue'); // 新品质检
const PlmProductDevelopAssemble = () => import('@/pages/PLM/product/develop/assemble/index.vue'); // 组装确认
const PlmProductDevelopBatchModifySku = () => import('@/pages/PLM/product/develop/batchModifySku/index.vue'); // 批量修改sku

// 商品管理
const PlmCommoditySpu = () => import('@/pages/PLM/commodity/spu/index.vue');
const PlmCommoditySpuDetails = () => import('@/pages/PLM/commodity/spu/details/index.vue');
const PlmCommoditySpuEdit = () => import('@/pages/PLM/commodity/spu/edit/index.vue');
const PlmCommoditySku = () => import('@/pages/PLM/commodity/sku/index.vue');
const PlmSupplyChainSupplier = () => import('@/pages/PLM/supplyChain/supplier/index.vue');
const PlmLawLOAOrCfsAttest = () => import('@/pages/PLM/law/LOAOrCfsAttest/index.vue'); // loa及cfs认证
const PlmLawAuthStatistics = () => import('@/pages/PLM/law/authStatistics/index.vue'); // loa及cfs认证统计
const PlmLawRecord = () => import('@/pages/PLM/law/record/index.vue');
const PlmLawRecordStatistics = () => import('@/pages/PLM/law/recordStatistics/index.vue');
const PlmBasicDataPresetData = () => import('@/pages/PLM/basicData/presetData/index.vue');
const PlmBasicDataPresetDataDetails = () => import('@/pages/PLM/basicData/presetData/details/index.vue');
const PlmBasicDataMaterialGrouping = () => import('@/pages/PLM/basicData/materialGrouping/List/index.vue');
const PlmBasicDataCategory = () => import('@/pages/PLM/basicData/category/List/index.vue');
const SalesMBVersionReplaceRule = () => import('@/pages/SAL/MaBangVersionReplace/ReplaceRule/List'); // 物料版本替换规则

const FinWriteoffDownload = () => import('@/pages/FIN/WriteOff/download/index.vue'); // 下载管理
const FinWriteoffWarehouse = () => import('@/pages/FIN/WriteOff/Warehouse/List/index.vue'); // AC出库单列表  (新)
const FinWriteoffWarehouse1 = () => import('@/pages/FIN/WriteOff/Warehouse1/List/index.vue'); // AC出库单列表 (旧)
const FinWriteoffRefundnote = () => import('@/pages/FIN/WriteOff/refundNote/List/index.vue'); //AC退货单列表
const FinWriteoffReceipt = () => import('@/pages/FIN/WriteOff/receipt/List/index.vue'); //AC收款单列表
const FinWriteoffReceivable = () => import('@/pages/FIN/WriteOff/receivable/List/index.vue'); //AC应收调整单
const FinWriteoffRecord = () => import('@/pages/FIN/WriteOff/record/List/index.vue'); //AC核销记录
const FinSalesMBorder = () => import('@/pages/SAL/OmsBusinessData/MaBangSaleOrder/List/index.vue'); // mb销售单
const FinSalesMBReturnOrder = () => import('@/pages/SAL/OmsBusinessData/MaBangReturnOrder/List/index.vue'); // mb退货单

const FinSalesWDTOrder = () => import('@/pages/SAL/OmsBusinessData/WangDianTongSaleOrder/List/index.vue'); // 旺店通销售单
const FinSalesWDTReturnOrder = () => import('@/pages/SAL/OmsBusinessData/WangDianTongReturnOrder/List/index.vue'); // 旺店通退货单

const FinConfigShopconfig = () => import('@/pages/FIN/Configuration/PlatformShop/List'); // 店铺关联配置
const FinConfigShoplist = () => import('@/pages/MDM/PlatformStore/List'); // 平台店铺列表

const FinPlatformDataMaterial = () => import('@/pages/MDM/K3CloudBaseData/Material/List');
const FinPlatformDataCustomer = () => import('@/pages/MDM/K3CloudBaseData/Customer/List');
const FinPlatformDataStock = () => import('@/pages/MDM/K3CloudBaseData/Stock/List');
const FinPlatformDataUnit = () => import('@/pages/MDM/K3CloudBaseData/Unit/List');
const FinPlatformDataOrganization = () => import('@/pages/MDM/K3CloudBaseData/Organization/List');
const GptReviewRating = () => import('@/pages/GPT/reviewRating/index.vue'); // 产品评论分析
const Analysis = () => import('@/pages/GPT/analysis/index.vue'); // GPT分析
const OriginalComments = () => import('@/pages/GPT/originalComments/index.vue'); // 原始评论
const CommentAnalysis = () => import('@/pages/GPT/commentAnalysis/index.vue'); // 评论分析

// 打印立项资料
const PrintData = () => import('@/pages/PLM/newProduct/developmentProgress/ViewProMater/printData.vue');
const ProApprovalMat = () => import('@/pages/PLM/newProduct/developmentProgress/project/proApprovalMat.vue');

// 开发进度
const DevProgress = () => import('@/pages/PLM/newProduct/developmentProgress/devProgress.vue');

// 开发进度
const DevPlan = () => import('@/pages/PLM/newProduct/plan/index.vue');

// 产品立项
const ProjectInitiation = () => import('@/pages/PLM/newProduct/developmentProgress/projectInitiation/index.vue');

// 对账核销
const Verification = () => import('@/pages/FIN/WriteOff/verification/index.vue');

// 门户首页
const HomePage = () => import('@/pages/PLM/gateway/homePage/homePage.vue');

// 消息通知配置
const InfoNotificConfigura = () => import('@/pages/PLM/gateway/infoNotificConfigura/infoNotificConfigura.vue');

// 通知中心
const Notifications = () => import('@/pages/PLM/gateway/notifications/notifications.vue');

// 汇总传输异常处理
const SumTransmisException = () => import('@/pages/FIN/WriteOff/sumTransmisException/index.vue');

// 核销金额差异记录
const WriteOffAmountDifference = () => import('@/pages/FIN/WriteOff/writeOffAmountDifference/index.vue');

// 新增物料
const AddMaterials = () => import('@/pages/PLM/product/addMaterials/index.vue');

// 核销任务管理
const WriteOffTask = () => import('@/pages/FIN/WriteOff/writeOffTask/List/index.vue');

// 原始单变更记录
const ChangeRecord = () => import('@/pages/FIN/WriteOff/changeRecord/List/index.vue');

// 核销数据监控
const VerificationDataMonitoring = () => import('@/pages/FIN/WriteOff/verificationDataMonitoring/index.vue');

// 增加小鹿同步金蝶出库单退货单的日志记录
const AddRecordsLog = () => import('@/pages/FIN/WriteOff/addRecordsLog/index.vue');

// 账单导入生成列表
const BillmportGener = () => import('@/pages/FIN/WriteOff/billmportGener/index.vue');
// 出库单传输监控（新增）
const WarehouseTransMonitor = () => import('@/pages/FIN/WriteOff/warehouseTransMonitor/index.vue');

// 退货单传输监控(新增)
const RefundnoteTransMonitor = () => import('@/pages/FIN/WriteOff/refundnoteTransMonitor/index.vue');

// 账单传输监控(新增)
const ReceiptTransMonitor = () => import('@/pages/FIN/WriteOff/receiptTransMonitor/index.vue');

//  异常跟踪汇总(新增)
const SumAbnormalTracks = () => import('@/pages/FIN/WriteOff/sumAbnormalTracks/index.vue');

// 马帮金蝶传输监控
const CaravanMonitor = () => import('@/pages/FIN/WriteOff/caravanMonitor/index.vue');
export default {
  basicLayout: BasicLayout,
  sysDepartmentList: SysDepartmentList,
  sysMenuList: SysMenuList,
  sysUserList: SysUserList,
  sysRoleList: SysRoleList,
  monitorPlatformStock: MonitorPlatformStock,
  monitorPlatformShip: MonitorPlatformShip,
  monitorPlatformShipFlowPath: MonitorPlatformShipFlowPath,
  monitorPlatformTransmission: MonitorPlatformTransmission,
  monitorPlatformOrder: MonitorPlatformOrder,
  fINWriteOffFeeCertificate: FINWriteOffFeeCertificate,
  fINWriteOffBillList: FINWriteOffBillList,
  platformStoreList: PlatformStoreList,
  plmProductMaterials: PlmProductMaterials,
  plmProductManuscript: PlmProductManuscript,
  plmProductManuscriptDetails: PlmProductManuscriptDetails,
  plmCommoditySpu: PlmCommoditySpu,
  plmCommoditySpuDetails: PlmCommoditySpuDetails,
  plmCommoditySpuEdit: PlmCommoditySpuEdit,
  plmCommoditySku: PlmCommoditySku,
  plmSupplyChainSupplier: PlmSupplyChainSupplier,
  plmLawLOAOrCfsAttest: PlmLawLOAOrCfsAttest,
  plmLawAuthStatistics: PlmLawAuthStatistics,
  plmLawRecord: PlmLawRecord,
  plmLawRecordStatistics: PlmLawRecordStatistics,
  plmBasicDataPresetData: PlmBasicDataPresetData,
  plmBasicDataPresetDataDetails: PlmBasicDataPresetDataDetails,
  plmBasicDataCategory: PlmBasicDataCategory,
  plmBasicDataMaterialGrouping: PlmBasicDataMaterialGrouping,
  salesMBVersionReplaceRule: SalesMBVersionReplaceRule,
  finWriteoffWarehouse: FinWriteoffWarehouse,
  finWriteoffRefundnote: FinWriteoffRefundnote,
  finWriteoffReceipt: FinWriteoffReceipt,
  finWriteoffReceivable: FinWriteoffReceivable,
  finWriteoffRecord: FinWriteoffRecord,
  finSalesMBorder: FinSalesMBorder,
  finSalesMBReturnOrder: FinSalesMBReturnOrder,
  finSalesWDTOrder: FinSalesWDTOrder,
  finSalesWDTReturnOrder: FinSalesWDTReturnOrder,
  finConfigShopconfig: FinConfigShopconfig,
  finConfigShoplist: FinConfigShoplist,
  finPlatformDataMaterial: FinPlatformDataMaterial,
  finPlatformDataCustomer: FinPlatformDataCustomer,
  finPlatformDataStock: FinPlatformDataStock,
  finPlatformDataUnit: FinPlatformDataUnit,
  finPlatformDataOrganization: FinPlatformDataOrganization,
  plmProductDevelopCreate: PlmProductDevelopCreate,
  plmProductDevelopNewQuality: PlmProductDevelopNewQuality,
  plmProductDevelop: PlmProductDevelop,
  plmProductDevelopDel: PlmProductDevelopDel,
  plmProductDevelopAssemble: PlmProductDevelopAssemble,
  plmProductDevelopBatchModifySku: PlmProductDevelopBatchModifySku,
  finWriteoffDownload: FinWriteoffDownload,
  gptReviewRating: GptReviewRating,
  analysis: Analysis,
  originalComments: OriginalComments,
  commentAnalysis: CommentAnalysis,
  printData: PrintData,
  devProgress: DevProgress,
  proApprovalMat: ProApprovalMat,
  devPlan: DevPlan,
  projectInitiation: ProjectInitiation,
  log: Log,
  verification: Verification,
  homePage: HomePage,
  infoNotificConfigura: InfoNotificConfigura,
  notifications: Notifications,
  sumTransmisException: SumTransmisException,
  writeOffAmountDifference: WriteOffAmountDifference,
  addMaterials: AddMaterials,
  writeOffTask: WriteOffTask,
  changeRecord: ChangeRecord,
  verificationDataMonitoring: VerificationDataMonitoring,
  plmProductDevelopAddLand: PlmProductDevelopAddLand,
  addRecordsLog: AddRecordsLog,
  billmportGener: BillmportGener,
  sumAbnormalTracks: SumAbnormalTracks,
  warehouseTransMonitor: WarehouseTransMonitor,
  refundnoteTransMonitor: RefundnoteTransMonitor,
  receiptTransMonitor: ReceiptTransMonitor,
  finWriteoffWarehouse1: FinWriteoffWarehouse1,
  caravanMonitor: CaravanMonitor
};

// {
//     path: '/BAS/Department/List',
//         name: 'BAS/Department/List',
//     meta: {
// ...meta,
//         title: '部门列表',
//         closable: true
// },
//     component: () => import('@/pages/BAS/Department/List')
// },
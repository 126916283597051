import { ref, reactive, onMounted, computed, nextTick } from 'vue';
export default {
  __name: 'index',
  props: {
    rule: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  setup(__props, {
    expose: __expose,
    emit
  }) {
    __expose();
    const props = __props;
    const handleClick = event => {
      emit(`on-menu-${event}`);
    };
    const __returned__ = {
      emit,
      props,
      handleClick,
      ref,
      reactive,
      onMounted,
      computed,
      nextTick
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
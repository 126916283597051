import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bcbf833c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "iPage"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Page = _resolveComponent("Page");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Page, {
    style: {
      "margin-top": "4px"
    },
    size: "small",
    total: 100
  })]);
}
import { defineStore } from "pinia";
import { Get, Post } from "@api/http";
import { getToken, removeToken, setToken } from "@/utils/auth";
import { useTreeOperate } from "@/core/CommonHooks/useTreeOperate";
const {
  method: {
    dfsTransFn
  }
} = useTreeOperate();
import constRouter from "@/router/constRouter";
import { render } from "vue";
import store from "@/store";
import PLM1 from "@/router/modules/PLM1";
const dfsTransFn1 = (tree, func, childrenKey = 'Childrens', level = 0, parentItem = {}) => {
  if (!Array.isArray(tree)) return [];
  level++;
  for (let i = 0; i < tree.length; i++) {
    let node = tree[i];
    func(node, parentItem);
    if (tree[i].FType === 2) {
      tree.splice(i, 1);
      i--;
    }

    // 如果子树存在，递归调用
    if (node && node[childrenKey] && node[childrenKey].length) {
      dfsTransFn1(node[childrenKey], func, childrenKey, level, node);
    }
  }
  return tree;
};
export const useUserStore = defineStore({
  id: 'user',
  // id必填，且需要唯一
  state: () => {
    return {
      token: getToken(),
      tokenObj: {},
      roles: [],
      userInfo: {},
      permissions: [],
      routerList: [],
      menuList: []
    };
  },
  actions: {
    async login(params) {
      const res = await Post('/Auth/Login', params);
      this.setToken(res === null || res === void 0 ? void 0 : res.AccessToken);
      this.setTokenObj(res);
      setToken(res === null || res === void 0 ? void 0 : res.AccessToken);
      return res;
    },
    async getUserInfo() {
      const res = await Get('/Auth/Permissions');
      console.log(res);
      this.setUserInfo(res === null || res === void 0 ? void 0 : res.UserInfo);
      this.setPermissions(res === null || res === void 0 ? void 0 : res.Permissions);
      let routerArr = JSON.parse(JSON.stringify(res.Permissions));
      let childrens = dfsTransFn1(routerArr, o => {
        o['children'] = o.Childrens;
        o['path'] = o.FPath;
        // o['redirect'] = o.FRedirect
        o['name'] = o.FComponent === 'basicLayout' ? o.FPath : o.FComponent;
        o['meta'] = {
          title: o.FName,
          auth: true,
          cache: o.FCache,
          closable: true,
          overflowVisible: o.FIsOverflowVisible,
          btnPermissions: o.Childrens.filter(v => v.FType === 2).map(v => v.FBtnIdentify)
        };
        if (o.FComponent) {
          o['component'] = constRouter[o.FComponent];
        } else {
          o['component'] = () => import('@/pages/placeholder/index.vue');
        }

        // 处理tag
        store.commit('admin/page/poolUp', {
          path: o.FPath,
          name: o.FComponent === 'basicLayout' ? o.FPath : o.FComponent,
          meta: {
            title: o.FName,
            auth: true,
            cache: o.FCache,
            closable: true
          }
        });
      });

      // 不知道为什么这样写
      store.commit('admin/page/poolUp', {
        path: '/PLM/product/uploadDesignDraft',
        name: 'PLM-product-uploadDesignDraft',
        meta: {
          title: '上传设计稿',
          auth: true,
          cache: true,
          closable: true
        }
      });
      store.commit('admin/page/poolUp', {
        path: '/plm/product/develop/editInfo',
        name: 'PLM-product-develop-editInfo',
        meta: {
          title: '修改组装信息',
          auth: true,
          cache: true,
          closable: true
        }
      });
      // 不知道为什么这样写 结束

      this.setRouterList([{
        children: [...childrens, PLM1],
        path: '/page',
        name: 'Page',
        component: constRouter['basicLayout']
      }]);
      this.setMenuList(dfsTransFn(res.Permissions, o => {
        o['children'] = o.Childrens;
        if (o.Childrens && o.Childrens.length > 0) {
          o['_showChildren'] = true;
        }
      }));
      return res;
    },
    async logout() {
      this.setToken('');
      this.setTokenObj('');
      this.setRouterList([]);
      this.setMenuList([]);
      this.setUserInfo({});
      this.setPermissions([]);
      removeToken();
    },
    setToken(value) {
      this.token = value;
    },
    setTokenObj(value) {
      this.tokenObj = value;
    },
    setUserInfo(value) {
      this.userInfo = value;
    },
    setPermissions(value) {
      this.permissions = value;
    },
    setRouterList(value) {
      console.log('pinia设置理由表');
      console.log(value);
      this.routerList = value;
    },
    setMenuList(value) {
      this.menuList = value;
    }
  },
  persist: {
    storage: localStorage,
    paths: ['token', 'tokenObj']
  }
});
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Input = _resolveComponent("Input");
  const _component_Icon = _resolveComponent("Icon");
  const _component_Tooltip = _resolveComponent("Tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Input, _mergeProps({
    style: {
      "width": "100%"
    },
    value: $setup.value,
    "onUpdate:value": _cache[0] || (_cache[0] = $event => $setup.value = $event)
  }, $setup.getProps, {
    placeholder: "请输入"
  }), null, 16 /* FULL_PROPS */, ["value"]), _createVNode(_component_Tooltip, {
    "max-width": "200",
    transfer: "",
    content: "可模糊搜索失败原因，比如：已关账、同步金蝶异常、物料编码、基本信息中的客户值等",
    placement: "left"
  }, {
    default: _withCtx(() => [_createVNode(_component_Icon, {
      type: "md-information-circle"
    })]),
    _: 1 /* STABLE */
  })]);
}
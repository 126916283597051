import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'dashboard-';
export default {
  path: '/dashboard',
  name: 'dashboard',
  redirect: {
    name: `${pre}console`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: 'console',
    name: `${pre}console`,
    meta: {
      ...meta,
      title: '主控台',
      closable: false,
      overflowVisible: true
    },
    component: () => import('@/pages/dashboard/console')
  }]
};
import { ref, toRefs, reactive, onMounted, computed, nextTick, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Post } from "@/api/http";
import { useStore } from 'vuex';
export default {
  __name: 'AuxiliaryData',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true
    },
    // 辅助资料Id
    showNumber: {
      type: Boolean,
      default: false
    },
    // Select Option 文本显示编码
    modelValue: {
      type: [Number, String],
      default: 0
    },
    // 值 主键Id
    returnFNumber: {
      type: Boolean,
      default: false
    } // 回传 FNumber
  },

  emits: ['on-save', 'on-cancel', 'on-create', 'update:modelValue'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    __expose();
    const props = __props;
    const {
      id,
      modelValue,
      returnFNumber
    } = toRefs(props);

    // 

    // 11
    const handleChange = event => {
      let selevtVal = selects.value.find(e => e.FEntryId == event);
      let value = returnFNumber.value == false ? event : selevtVal.FNumber;
      emit('update:modelValue', value);
    };

    /**
     * 默认 选中值处理逻辑
     */
    const selects = ref([]);
    const defaultValue = ref(0);
    watch(modelValue, newVal => {
      console.log(`监听-${newVal}`);
      if (newVal === '' || newVal === undefined || newVal === 0) {
        defaultValue.value = '';
        return;
      }
      let row = selects.value.find(e => e.FNumber == newVal);
      if (row != undefined) {
        defaultValue.value = row.FEntryId;
      }
    }, {
      immediate: true,
      deep: true
    });

    /**
     * 
     */
    const getListData = () => {
      let rules = [{
        "Key": "FId",
        "Value": id.value,
        "Operator": "Equals",
        "LogicalOperator": "or"
      }];
      Post("/BAS/AuxiliaryDataEntry/PageList", {
        "FRules": rules,
        "FOrders": [],
        "FPageIndex": 1,
        "FPageSize": 1000
      }).then(res => {
        selects.value = res.Items;
      });
    };
    onMounted(() => {
      getListData();
    });
    const __returned__ = {
      props,
      id,
      modelValue,
      returnFNumber,
      emit,
      handleChange,
      selects,
      defaultValue,
      getListData,
      ref,
      toRefs,
      reactive,
      onMounted,
      computed,
      nextTick,
      watch,
      get useRoute() {
        return useRoute;
      },
      get useRouter() {
        return useRouter;
      },
      get Post() {
        return Post;
      },
      get useStore() {
        return useStore;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
export default {
  path: '/SAL',
  title: '销售管理',
  children: [{
    title: "马帮运营助手",
    children: [{
      title: "马帮运营助手",
      children: [
      // {
      //     path: "/Sales/MaBangVersionReplace/ReplaceLog/List",
      //     title: '物料版本替换日志'
      // },
      {
        path: "/Sales/MaBangVersionReplace/ReplaceRule/List",
        title: '物料版本替换规则'
      }
      // {
      //     path: "/Sales/MaBangVersionReplace/ShopConfig/List",
      //     title: '平台店铺替换开启状态'
      // }
      ]
    }
    // {
    //     title: "马帮数据监控",
    //     children: [
    //         {
    //             path: "/Sales/MaBangVersionReplaceLog/List",
    //             title: '马帮缺货数据跟踪'
    //         }
    //     ]
    // }
    ]
  }
  // {
  //     title: "平台业务单据",
  //     children: [
  //         {
  //             title: "OMS业务数据",
  //             children: [
  //                 {
  //                     path: "/SAL/OmsBusinessData/MaBangSaleOrder/List",
  //                     title: '马帮销售单'
  //                 },
  //                 {
  //                     path: "/SAL/OmsBusinessData/MaBangReturnOrder/List",
  //                     title: '马帮退货单'
  //                 },
  //                 {
  //                     path: "/SAL/OmsBusinessData/WangDianTongSaleOrder/List",
  //                     title: '旺店通销售单'
  //                 },
  //                 {
  //                     path: "/SAL/OmsBusinessData/WangDianTongReturnOrder/List",
  //                     title: '旺店通退货单'
  //                 },
  //             ]
  //         },
  //         // {
  //         //     title: "平台业务数据",
  //         //     children: [
  //         //         {
  //         //             path: "/SAL/PlatformBusinessData/MaBangSaleOrder/List",
  //         //             title: '马帮销售单'
  //         //         },
  //         //         {
  //         //             path: "/SAL/PlatformBusinessData/MaBangReturnOrder/List",
  //         //             title: '马帮退货单'
  //         //         },
  //         //         {
  //         //             path: "/SAL/PlatformBusinessData/WangDianTongSaleOrder/List",
  //         //             title: '旺店通销售单'
  //         //         },
  //         //         {
  //         //             path: "/SAL/PlatformBusinessData/WangDianTongReturnOrder/List",
  //         //             title: '旺店通退货单'
  //         //         },
  //         //     ]
  //         // }
  //     ]
  // }
  ]
};
/**
 * 
 */
export default {
  path: '/MDM',
  title: '平台店铺管理',
  header: 'Base',
  children: [{
    title: "平台店铺",
    children: [{
      title: "平台店铺",
      children: [{
        path: "/MDM/PlatformStore/List",
        title: '平台店铺列表'
      }
      // {
      //     path: "/MDM/PlatformStoreType/List",
      //     title: '平台店铺类型'
      // }
      ]
    }]
  }
  // {
  //     title: "K3Cloud基础数据",
  //     children: [
  //         {
  //             title: "基础资料",
  //             children: [
  //                 {
  //                     path: `/MDM/K3CloudBaseData/Material/List`,
  //                     title: '物料列表',
  //                 },
  //                 {
  //                     path: `/MDM/K3CloudBaseData/Customer/List`,
  //                     title: '客户列表',
  //                 },
  //                 {
  //                     path: `/MDM/K3CloudBaseData/Stock/List`,
  //                     title: '仓库列表',
  //                 }
  //             ]
  //         },
  //         {
  //             title: "公共资料",
  //             children: [
  //                 {
  //                     path: `/MDM/K3CloudBaseData/Unit/List`,
  //                     title: '计量单位列表',
  //                 },
  //                 {
  //                     path: `/MDM/K3CloudBaseData/Organization/List`,
  //                     title: '组织列表',
  //                 }
  //             ]
  //         }
  //     ]
  // }
  ]
};
import "ant-design-vue/es/tooltip/style";
import _Tooltip from "ant-design-vue/es/tooltip";
import "ant-design-vue/es/input-number/style";
import _InputNumber from "ant-design-vue/es/input-number";
import "ant-design-vue/es/button/style";
import _Button from "ant-design-vue/es/button";
import "ant-design-vue/es/progress/style";
import _Progress from "ant-design-vue/es/progress";
import "ant-design-vue/es/upload/style";
import _Upload from "ant-design-vue/es/upload";
import "ant-design-vue/es/form/style";
import _Form from "ant-design-vue/es/form";
import "ant-design-vue/es/input/style";
import _Input from "ant-design-vue/es/input";
import "ant-design-vue/es/config-provider/style";
import _ConfigProvider from "ant-design-vue/es/config-provider";
import "ant-design-vue/es/date-picker/style";
import _DatePicker from "ant-design-vue/es/date-picker";
import "ant-design-vue/es/tabs/style";
import _Tabs from "ant-design-vue/es/tabs";
import "ant-design-vue/es/tag/style";
import _Tag from "ant-design-vue/es/tag";
import "ant-design-vue/es/select/style";
import _Select from "ant-design-vue/es/select";
import "ant-design-vue/es/tree/style";
import _Tree from "ant-design-vue/es/tree";
import './public-path.js';

// Vue
import { createApp, h, getCurrentInstance } from 'vue';
import App from '@/App';

// 配置
import Setting from '@/setting';

// 混合
import mixinApp from '@/mixins/app';

// 插件
import plugins from '@/plugins';

// store
import store from '@/store/index';
import "jsoneditor/dist/jsoneditor.css";
// import 'jsoneditor/dist/img/jsoneditor-icons.svg';

// store-pinia
import storePinia from '@/storePinia/index';

// ViewUIPlus
import ViewUIPlus from 'view-ui-plus';
import { Message, Notice } from 'view-ui-plus';

// 菜单和路由
import router from '@/router';
import menuHeader from '@/menu/header';
import { frameInRoutes } from '@/router/routes';

// 多语言
import i18n from '@/i18n';
import { loadRemoteLocaleMessages } from '@/i18n/locale';

// 内置组件
import iLink from '@/components/link';
import iFrame from '@/components/frame';
import iDrawer from '@/components/drawer';
import iDrawerEdit from '@/components/drawerEdit';
import iMenu from '@/components/menu';
import iFilter from '@/components/filter';
import iSidebar from "@/components/sidebar";
import iPage from "@/components/page";
import iTable from "@/components/table";
import iTableList from "@/components/tableList";

// Core
import iAuxiliaryData from "@/core/Element/AuxiliaryData/AuxiliaryData";

// 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 View UI Plus 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme
import './styles/index.less';
import './permission'; // 权限控制

import "./styles/tailwindcss.css";
import Util from "@/core/Libs/Util.js";

// 第一次进入时，取菜单数据用于更新
let isFirstRouteChange = true;

// 使用antdV

import print from 'vue3-print-nb';
let app = null;
function render(props = {}) {
  const {
    container
  } = props;
  app = createApp({
    mixins: [mixinApp],
    render: () => h(App),
    created() {
      // 处理路由 得到每一级的路由设置
      this.$store.commit('admin/page/init', frameInRoutes);
      // 设置顶栏菜单
      if (!Setting.dynamicMenu) this.$store.commit('admin/menu/setHeader', menuHeader);
      // 加载用户登录的数据
      this.$store.dispatch('admin/account/load');
      // 初始化全屏监听
      this.$store.dispatch('admin/layout/listenFullscreen');
      // 将根实例存全局，可在特殊场景下调用
      if (window) {
        window.$app = getCurrentInstance();
        window.$app.$t = i18n.global.t;
      }
      // 加载远程 i18n
      if (Setting.i18n.remote) loadRemoteLocaleMessages(i18n);
    },
    watch: {
      // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
      '$route'(to, from) {
        this.$store.dispatch('admin/menu/setMenuList', to);
        if (Setting.dynamicMenu) {
          if (isFirstRouteChange) {
            isFirstRouteChange = false;
            // 每次进入 app 时，获取一次菜单
            this.$store.dispatch('admin/menu/getMenuList', to);
          }
        }
        this.appRouteChange(to, from);
      }
    }
  });
  app.use(storePinia);
  app.use(router);
  app.use(store);
  app.use(i18n);
  app.use(print);
  app.use(ViewUIPlus, {
    i18n
  });
  app.use(plugins);
  app.component('i-link', iLink);
  app.component('i-frame', iFrame);
  app.component('i-drawer', iDrawer);
  app.component('i-drawer-edit', iDrawerEdit);
  app.component('i-menu', iMenu);
  app.component('i-filter', iFilter);
  app.component('i-sidebar', iSidebar);
  app.component('i-page', iPage);
  app.component('i-table', iTable);
  app.component('i-table-list', iTableList);

  // Core
  app.component('iAuxiliaryData', iAuxiliaryData);

  // antdV 组件
  app.component(_Tree.name, _Tree);
  app.component(_Select.name, _Select);
  app.component(_Tag.name, _Tag);
  app.component(_Tabs.name, _Tabs);
  app.component(_DatePicker.name, _DatePicker);
  app.component(_ConfigProvider.name, _ConfigProvider);
  app.component('a-input', _Input);
  app.component('a-form', _Form);
  app.component('a-form-item', _Form.Item);
  app.component(_Upload.name, _Upload);
  app.component(_Progress.name, _Progress);
  app.component(_Button.name, _Button);
  app.component(_InputNumber.name, _InputNumber);
  app.component(_Tooltip.name, _Tooltip);
  // import {useRoute} from "vue-router";

  app.provide('global', {
    $Util: Util,
    $Message: Message
  });

  /**按钮权限指令**/
  // app.directive('has', {
  //     mounted (el, binding, vnode) {
  //         // const route = useRoute()
  //         console.log(route)
  //         console.log(el)
  //         console.log(binding.value)
  //         console.log(vnode)
  //         console.log(vnode['context'])
  //         // if(binding.value) {
  //         //     let btnPermissionsArr = []
  //         //     btnPermissionsArr = vnode.context.$route.meta.btnPermissions;
  //         //     console.log(btnPermissionsArr)
  //         //     if (!btnPermissionsArr.includes(binding.value)) {
  //         //         el.parentNode.removeChild(el);
  //         //     }
  //         // }
  //     },
  // });

  app.mount(container ? container.querySelector('#plmApp') : '#plmApp');
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}
export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}
export async function mount(props) {
  console.log('[vue] props from main framework', props);
  render(props);
}
export async function unmount() {
  console.log('进来了吗？');
  console.log(app);
  app.unmount();
  // router()
  // app.$el.innerHTML = ''
  // app = null
}
import rule from "./rule.vue";
import { JointListRules } from "./rules.js";
import { ref, toRefs, reactive, watch, onMounted, computed, nextTick } from 'vue';
export default {
  __name: 'index',
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  setup(__props, {
    expose: __expose,
    emit
  }) {
    __expose();
    const props = __props;
    const {
      fields
    } = toRefs(props);
    const arrowDropdownCount = ref(4);

    // 搜索
    const ruleData = ref([]);

    // 规则值更新
    const onRuleChange = ({
      index,
      value
    }) => {
      ruleData.value[index] = {
        ...value
      };
    };

    /**
     * 搜索提交
     */
    const onSearchSubmit = evnet => {
      let _ruleData = JointListRules(ruleData.value);
      emit(`on-change`, _ruleData);
    };

    // 释放筛选规则
    const onArrowDropdown = () => {
      arrowDropdownCount.value = arrowDropdownCount.value == 2 ? ruleData.value.length : 2;
    };

    /**
     * onMounted
     */
    onMounted(() => {
      ruleData.value = fields.value.map((e, i) => {
        return {
          field: e.value,
          operators: null,
          value: null
        };
      });
    });
    const __returned__ = {
      props,
      fields,
      arrowDropdownCount,
      ruleData,
      emit,
      onRuleChange,
      onSearchSubmit,
      onArrowDropdown,
      rule,
      get JointListRules() {
        return JointListRules;
      },
      ref,
      toRefs,
      reactive,
      watch,
      onMounted,
      computed,
      nextTick
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
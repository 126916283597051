import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
export default {
  path: '/MDM',
  name: 'mdm',
  meta,
  component: BasicLayout,
  children: [{
    path: '/MDM/PlatformStoreType/List',
    name: 'MDM-PlatformStoreType-List',
    meta: {
      ...meta,
      title: '平台店铺列表',
      closable: true
    },
    component: () => import('@/pages/MDM/PlatformStoreType/List')
  }, {
    path: '/MDM/K3CloudBaseData/Material/List',
    name: 'MDM-K3CloudBaseData-Material-List',
    meta: {
      ...meta,
      title: '物料列表',
      closable: true
    },
    component: () => import('@/pages/MDM/K3CloudBaseData/Material/List')
  },
  //  Customer
  {
    path: '/MDM/K3CloudBaseData/Customer/List',
    name: 'MDM-K3CloudBaseData-Customer-List',
    meta: {
      ...meta,
      title: '客户列表',
      closable: true
    },
    component: () => import('@/pages/MDM/K3CloudBaseData/Customer/List')
  },
  // 仓库 Stock
  {
    path: '/MDM/K3CloudBaseData/Stock/List',
    name: 'MDM-K3CloudBaseData-Stock-List',
    meta: {
      ...meta,
      title: '仓库列表',
      closable: true
    },
    component: () => import('@/pages/MDM/K3CloudBaseData/Stock/List')
  },
  // 计量单位 Unit
  {
    path: '/MDM/K3CloudBaseData/Unit/List',
    name: 'MDM-K3CloudBaseData-Unit-List',
    meta: {
      ...meta,
      title: '计量单位列表',
      closable: true
    },
    component: () => import('@/pages/MDM/K3CloudBaseData/Unit/List')
  },
  // 组织 Organization
  {
    path: '/MDM/K3CloudBaseData/Organization/List',
    name: 'MDM-K3CloudBaseData-Organization-List',
    meta: {
      ...meta,
      title: '组织列表',
      closable: true
    },
    component: () => import('@/pages/MDM/K3CloudBaseData/Organization/List')
  }]
};
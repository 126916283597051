import { createRouter, createWebHistory, createWebHashHistory, createMemoryHistory } from 'vue-router';
import ViewUIPlus from 'view-ui-plus';
import util from '@/libs/util';
import Setting from '@/setting';
import store from '@/store/index';

// 路由数据
import routes from './routes';
let base = window.__POWERED_BY_QIANKUN__ ? '/plmApp/' : '/';

// 导出路由 在 main.js 里使用
const router = createRouter({
  // base: ,
  // mode: 'history',
  history: Setting.routerMode === 'history' ? createWebHistory(base) : Setting.routerMode === 'hash' ? createWebHashHistory(base) : createMemoryHistory(base),
  routes
});
export default router;
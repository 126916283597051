export default {
  path: '/EHR',
  title: '人资管理',
  children: [{
    title: "基础管理",
    children: [{
      title: "职员",
      children: [{
        path: "/EHR/Base/Staff/List",
        title: '职员列表'
      }]
    }, {
      title: "部门",
      children: [{
        path: "/EHR/Base/Department/List",
        title: '部门列表'
      }]
    }]
  }]
};
import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'sales-';
export default {
  path: '/Sales/PlatformBusinessData',
  name: 'Sales-PlatformBusinessData',
  redirect: {
    name: `${pre}VersionReplaceRecord`
  },
  meta,
  component: BasicLayout,
  children: []
};
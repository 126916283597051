export default {
  path: '/BAS',
  title: 'BAS管理',
  children: [{
    title: "账号权限",
    children: [{
      title: "账号",
      children: [{
        path: "/BAS/User/List",
        title: '账号列表'
      }]
    }, {
      title: "角色",
      children: [{
        path: "/BAS/Role/List",
        title: '角色列表'
      }]
    }, {
      title: "基础资料",
      children: [{
        path: "/BAS/AuthRule/List",
        title: '权限规则'
      }, {
        path: "/BAS/AuthorityItem/List",
        title: '权限项目列表'
      }]
    }]
  }, {
    title: "数据建模",
    children: [{
      title: "应用",
      children: [{
        path: "/BAS/Menu/List",
        title: "菜单列表"
      }, {
        path: "/BAS/Router/List",
        title: "路由列表"
      }, {
        path: "/BAS/BusinessObject/List",
        title: "业务对象列表"
      }, {
        path: "/BAS/AppModule/List",
        title: "模块列表"
      }]
    }, {
      title: "数据实体",
      children: [{
        path: "/Sales/MaBangVersionReplaceLog/List",
        title: "数据表"
      }, {
        path: "/Sales/MaBangVersionReplaceLog/List",
        title: "数据字段"
      }]
    }]
  }, {
    title: "基础管理",
    children: [{
      title: "基础资料",
      children: [{
        path: "/Bas/BasicData/List",
        title: '基础资料管理'
      }]
    }]
  }]
};
import "ant-design-vue/es/notification/style";
import _notification from "ant-design-vue/es/notification";
import "ant-design-vue/es/message/style";
import _message from "ant-design-vue/es/message";
import Drawer from "@/components/drawer/index1.vue";
import { ref, toRefs, reactive, watch, inject, onMounted, computed, nextTick } from 'vue';
import { Get, Post, Put } from "@/api/http.js";
import { useTreeOperate } from "@/core/CommonHooks/useTreeOperate";
import { useVaildator } from "@/core/CommonHooks/useVaildator";
import { Message, Spin } from "view-ui-plus";
import dayjs from "dayjs";
import BasicUpload from "@/components/Upload/index.vue";
import { UploadOutlined, InfoCircleOutlined, DiffOutlined, ReadOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { useObs } from "@/components/Upload/hooks/useObs";
export default {
  __name: 'import',
  props: {
    selectedTable: {
      type: Object,
      default: () => {}
    },
    url: {
      type: String,
      default: ''
    },
    status: {
      type: Number,
      default: 1
    }
  },
  emits: ['handSuccess'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    __expose();
    const props = __props;

    // 内部关闭没有实现： 没有思路 后续更新， 暂时使用ref 关闭弹框
    const {
      method: {
        selectVaildator
      }
    } = useVaildator();
    const {
      fileUpload,
      obsDataInit,
      progressCallbackValue,
      resultStatus,
      resultPath
    } = useObs();
    const form = ref({
      fileList: []
    });
    const type = ref(1);
    const recurrenceData = ref([]);
    const {
      method: {
        dfsTransFn
      }
    } = useTreeOperate();
    const beforeUpload = file => {
      form.value.fileList = [file];
      return false;
    };
    const handleAdd = () => {
      formData.value.PlatformOrderIds.push('');
    };
    const handleRemove = file => {
      const index = form.value.fileList.indexOf(file);
      const newFileList = form.value.fileList.slice();
      newFileList.splice(index, 1);
      form.value.fileList = newFileList;
    };

    // 供应商数据
    const supplierData = ref([]);
    const loading = ref(false);
    const getSupplierList = async (FName = '') => {
      try {
        loading.value = true;
        let {
          Items
        } = await Post('/PLM/Supplier/List', {
          "FPageSize": 200,
          "FPageIndex": 1,
          "FOrders": [],
          "FRules": [{
            "Key": "FName",
            "LogicalOperator": "and",
            "Operator": "Contains",
            "Value": FName
          }]
        });
        supplierData.value = Items;
        loading.value = false;
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    };
    const unitData = ref([]);
    // 物料单位数据
    const getDictList = async () => {
      try {
        let {
          Items
        } = await Post('/PLM/Presets/ItemList', {
          "FPageSize": 9999,
          "FPageIndex": 1,
          "FOrders": [],
          "FRules": [{
            "Key": "FPresetsId",
            "LogicalOperator": "and",
            "Operator": "Equals",
            "Value": "395643651542725"
          }]
        });
        unitData.value = Items;
      } catch (e) {
        console.log(e);
      }
    };
    onMounted(() => {
      getDictList();
      getSupplierList();
    });

    /**
     * 页面数据
     */
    const formData = ref({
      // PlatformOrderIds: [''],
      PlatformOrderIds: '',
      ExpressTimeStart: '',
      ExpressTimeEnd: '',
      MabangApp: ''
    });
    const refForm = ref(null);
    const formRule = {
      FName: [{
        required: true,
        message: '请输入物料名称',
        trigger: 'blur'
      }, {
        max: 200,
        message: '长度不能超过200',
        trigger: 'blur'
      }],
      FMaterialGroupId: [{
        required: true,
        message: '请选择物料分组',
        validator: selectVaildator,
        trigger: 'change'
      }],
      // sx: [{ required: true, message: '请选择物料属性', trigger: 'change' }],
      FUnitId: [{
        required: true,
        message: '请选择单位',
        validator: selectVaildator,
        trigger: 'change'
      }],
      PlatformOrderIds: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }],
      ExpressTimeStart: [{
        required: true,
        type: 'date',
        message: '请选择时间',
        trigger: 'change'
      }],
      ExpressTimeEnd: [{
        required: true,
        type: 'date',
        message: '请选择时间',
        trigger: 'change'
      }],
      MabangApp: [{
        required: true,
        validator: selectVaildator,
        message: '请选择',
        trigger: 'change'
      }]
    };
    onMounted(() => {
      refForm.value.resetFields();
    });
    const upDrawerRef = ref(null);
    watch(() => upDrawerRef.value && upDrawerRef.value.visible, nV => {
      if (nV) {
        type.value = 1;
        form.value.fileList = [];
        obsDataInit();
        switch (props.status) {
          case 1:
            // 新加状态重置表单
            formData.value.PlatformOrderIds = '';
            formData.value.ExpressTimeStart = '';
            formData.value.ExpressTimeEnd = '';
            formData.value.MabangApp = '';
            refForm.value.resetFields();
            break;
          case 2:
            formData.value.PlatformOrderIds = '';
            formData.value.MabangApp = '';
            refForm.value.resetFields();
            break;
          case 3:
            formData.value.PlatformOrderIds = '';
            return;
        }
      }
    });

    // 1
    const onSave = () => {
      if (form.value.fileList.length < 1) {
        _message.warn('请选择文件');
        return;
      }
      const formData = new FormData();
      let flag = false;
      form.value.fileList.forEach(file => {
        formData.append('file', file);

        // 获取文件的扩展名
        const fileExtension = file.name.split('.').pop().toLowerCase();
        // if (!excelAccept.includes(fileExtension)) {
        //   message.error('只能上传表格文件（.csv, .xls, .xlsx）');
        //   flag = true
        // }
      });

      console.log(formData);
      console.log(form.value.fileList);
      type.value = 2;
      fileUpload(form.value.fileList[0]);
    };

    // 点击取消
    const onCancel = () => {
      // refForm.value.resetFields();
      form.value.fileList = [];
      upDrawerRef.value.visible = false;
    };
    watch(resultStatus, async n => {
      if (n === 200) {
        let res = await Post('/FIN/ExaminRecord/upload', {
          Path: resultPath.value
        });
        // message.success('文件已上传成功，结果请到下载管理页面查看')
        _notification.open({
          message: '提示',
          duration: null,
          description: '文件已上传成功，结果请到下载管理页面查看',
          onClick: () => {
            console.log('Notification Clicked!');
          }
        });
        onCancel();
      } else if (n === 404) {
        _message.error('上传失败，请重新上传');
      }
    });
    const __returned__ = {
      selectVaildator,
      fileUpload,
      obsDataInit,
      progressCallbackValue,
      resultStatus,
      resultPath,
      props,
      emit,
      form,
      type,
      recurrenceData,
      dfsTransFn,
      beforeUpload,
      handleAdd,
      handleRemove,
      supplierData,
      loading,
      getSupplierList,
      unitData,
      getDictList,
      formData,
      refForm,
      formRule,
      upDrawerRef,
      onSave,
      onCancel,
      Drawer,
      ref,
      toRefs,
      reactive,
      watch,
      inject,
      onMounted,
      computed,
      nextTick,
      get Get() {
        return Get;
      },
      get Post() {
        return Post;
      },
      get Put() {
        return Put;
      },
      get useTreeOperate() {
        return useTreeOperate;
      },
      get useVaildator() {
        return useVaildator;
      },
      get Message() {
        return Message;
      },
      get Spin() {
        return Spin;
      },
      get dayjs() {
        return dayjs;
      },
      BasicUpload,
      get UploadOutlined() {
        return UploadOutlined;
      },
      get InfoCircleOutlined() {
        return InfoCircleOutlined;
      },
      get DiffOutlined() {
        return DiffOutlined;
      },
      get ReadOutlined() {
        return ReadOutlined;
      },
      get EyeOutlined() {
        return EyeOutlined;
      },
      get message() {
        return _message;
      },
      get notification() {
        return _notification;
      },
      get useObs() {
        return useObs;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
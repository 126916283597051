import { nextTick, onMounted, ref, unref } from "vue";
import { isBoolean } from "@/utils/is";
import { getViewportOffset } from "@/utils/domUtills";
import { useWindowSizeFn } from "@/hooks/event/useWindowSizeFn";
import { useDebounceFn } from "@vueuse/core";
export function useTableScroll(tableElRef, wrapRef, formRef) {
  let bodyEl = null;
  let paginationEl = null;
  let footerEl = null;
  const calcHeight = ref(0);
  const debounceRedoHeight = useDebounceFn(redoHeight, 100);
  async function calcTableHeight() {
    // const { resizeHeightOffset, pagination, maxHeight, isCanResizeParent, useSearchForm } =
    //     unref(propsRef);
    // const tableData = unref(getDataSourceRef);

    const table = unref(tableElRef);
    if (!table) return;
    const tableEl = table.$el;
    if (!tableEl) return;
    if (!bodyEl) {
      bodyEl = tableEl.querySelector('.ivu-table-body');
      if (!bodyEl) return;
    }
    // console.log(bodyEl.scrollHeight)
    // console.log(bodyEl.clientHeight)

    const hasScrollBarY = bodyEl.scrollHeight > bodyEl.clientHeight;
    // console.log(hasScrollBarY)

    // if (hasScrollBarY) {
    //     console.log(tableEl)
    //     tableEl.classList.contains('ivu-table-overflowY') &&
    //     tableEl.classList.remove('ivu-table-overflowY');
    // } else {
    //     !tableEl.classList.contains('ivu-table-overflowY') && tableEl.classList.add('ivu-table-overflowY');
    // }

    // bodyEl.style.height = 'unset';

    // if (!unref(getCanResize) || !unref(tableData) || tableData.length === 0) return;

    await nextTick();

    // 从底部高度到自定义偏移量的表高度
    let paddingHeight = 32;
    // 分页高度
    let paginationHeight = 2;
    const wrap = unref(wrapRef);
    paginationEl = wrap.querySelectorAll('.page')[0];
    if (paginationEl) {
      const offsetHeight = paginationEl.offsetHeight;
      paginationHeight += offsetHeight || 0;
    } else {
      // TODO First fix 24
      paginationHeight += 24;
    }
    let bottomIncludeBody = 0;
    if (unref(wrapRef)) {
      const tablePadding = 12;
      const formMargin = 16;
      let paginationMargin = 10;
      const wrapHeight = unref(wrapRef).offsetHeight || 0;

      // 5 是因为有5px margin
      let formHeight = unref(formRef) && unref(formRef).$el && unref(formRef).$el.offsetHeight || 1;
      if (formHeight) {
        formHeight += formMargin;
      }
      // if (isBoolean(pagination) && !pagination) {
      //     paginationMargin = 0;
      // }
      // if (isBoolean(useSearchForm) && !useSearchForm) {
      //     paddingHeight = 0;
      // }

      bottomIncludeBody = wrapHeight - formHeight - tablePadding - paginationMargin;
    } else {
      // Table height from bottom
      bottomIncludeBody = getViewportOffset(headEl).bottomIncludeBody;
    }
    let height = bottomIncludeBody - paddingHeight - paginationHeight;
    // height = (height > maxHeight ? (maxHeight) : height) || height;
    // setHeight(height);

    // bodyEl.style.height = `${height}px`;
    // bodyEl.style.overflowY = `scroll`;
    // bodyEl.style.overflowx = `unset`;
    calcHeight.value = height;
  }
  useWindowSizeFn(calcTableHeight, 280);
  onMounted(() => {
    calcTableHeight();
    nextTick(() => {
      debounceRedoHeight();
    });
  });
  function redoHeight() {
    nextTick(() => {
      calcTableHeight();
    });
  }
  return {
    calcHeight,
    redoHeight
  };
}
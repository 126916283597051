import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Option = _resolveComponent("Option");
  const _component_Select = _resolveComponent("Select");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode(" 基础组件：辅助资料 "), _createVNode(_component_Select, {
    modelValue: $setup.defaultValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.defaultValue = $event),
    onOnChange: $setup.handleChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selects, (item, index) => {
      return _openBlock(), _createBlock(_component_Option, {
        value: item.FEntryId
      }, {
        default: _withCtx(() => [$props.showNumber == true ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createTextVNode("(" + _toDisplayString(item.FNumber) + ")", 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), _createTextVNode(_toDisplayString(item.FName), 1 /* TEXT */)]),

        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]);
    }), 256 /* UNKEYED_FRAGMENT */))]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */);
}
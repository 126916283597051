import _ from 'lodash';
export default {
  __name: 'index',
  props: {
    maskClosable: {
      type: Boolean,
      default: false
    },
    // 是否允许点击遮罩层关闭
    closable: {
      type: Boolean,
      default: false
    },
    // 
    modelValue: {
      type: Boolean,
      default: false
    },
    // 
    header: {
      type: Boolean,
      default: true
    },
    // 
    width: {
      type: Number,
      default: 500
    },
    // 
    isCreate: {
      type: Boolean,
      default: false
    },
    // 是否使用 创建 按钮
    isSave: {
      type: Boolean,
      default: true
    },
    // 是否使用 保存 按钮
    title: {
      type: String,
      default: "编辑"
    } // 
  },

  emits: ['on-save', 'on-cancel', 'on-create', 'update:modelValue'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    __expose();
    const props = __props;

    /**
     * 取消
     */
    const onCancel = () => {
      emit('update:modelValue', false);
    };

    /**
     * 保存
     */
    const onSave = () => {
      emit('on-save');
    };
    // const onSave = _.debounce(() => {
    //   emit('on-save');
    // }, 2000);

    /**
     * 创建
     */
    const onCreate = () => {
      emit('on-create');
    };

    /**
     * 显示状态发生改变
     */
    const onVisibleChange = event => {
      emit('update:modelValue', event);
    };
    const __returned__ = {
      props,
      emit,
      onCancel,
      onSave,
      onCreate,
      onVisibleChange,
      get _() {
        return _;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
import iMenuSideItem from './menu-item';
import iMenuSideTitle from './menu-title';
import siderMenuBadge from '../mixins/sider-menu-badge';
export default {
  name: 'iMenuSideSubmenu',
  components: {
    iMenuSideItem,
    iMenuSideTitle
  },
  mixins: [siderMenuBadge],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
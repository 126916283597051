export const useTreeOperate = () => {
  const method = {
    /**
     *  深度优先遍历
     *  @params {Array} tree 树数据
     *  @params {Function} func 操作函数
     * @params {String} childrenKey 子节点的 key
     * @params {String} level 级别
     * @params {String} parentItem 父节点
     * */
    dfsTransFn: (tree, func, childrenKey = 'Childrens', level = 0, parentItem = {}) => {
      if (!Array.isArray(tree)) return [];
      level++;
      tree.forEach(node => {
        func(node, parentItem);
        node['levelName'] = level;
        // 如果子树存在，递归调用
        if (node[childrenKey] && node[childrenKey].length) {
          method.dfsTransFn(node[childrenKey], func, childrenKey, level, node);
        }
      });
      return tree;
    }
  };
  return {
    method
  };
};
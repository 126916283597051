import { JointListRules } from "./rules.js";
import { ref, toRefs, reactive, watch, onMounted, computed, nextTick } from 'vue';
export default {
  __name: 'rule',
  props: {
    fields: {
      type: Array,
      required: true
    },
    rule: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['on-change'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    __expose();
    const props = __props;
    const {
      fields,
      rule,
      index
    } = toRefs(props);

    // 规则数据类型
    const dataType = ref("String");

    // 规则数据
    const ruleData = ref({
      field: null,
      operators: null,
      value: null
    });

    // 配置文件
    const configData = ref({});

    // 重算表达式数据
    const refreshOperators = fieldName => {
      // 获取字段配置
      let field = fields.value.find(e => e.value == fieldName);

      // 根据字段取配置信息
      dataType.value = field.type;

      // 表达式 select 处理
      operators.value = _operators.filter(e => e.types.indexOf(dataType.value) > -1);
      ruleData.value.operators = operators.value[0].value;
    };

    /**
     * 字段 Select 值发生变更
     */
    const onFieldChange = fieldName => {
      refreshOperators(fieldName);
      emitOnChange();
    };

    // 表达式 Select 值发生改变
    const onOperatorsChange = event => {
      emitOnChange();
    };

    // String 类型值发生改变
    const onStringValueChange = evnet => {
      emitOnChange();
    };

    // Bool 类型值发生改变
    const onBoolValueChange = event => {
      emitOnChange();
    };

    // 回抛事件
    const emitOnChange = () => {
      console.log("ruleData.value", ruleData.value);
      emit(`on-change`, {
        "index": index.value,
        "value": {
          ...ruleData.value
        }
      });
    };
    onMounted(() => {
      // 提取配置文件
      configData.value = fields.value.find(e => e.value === rule.value.field).config || undefined;

      // 规则字段
      ruleData.value.field = rule.value.field;
      // 重算可用表达式数据
      refreshOperators(rule.value.field);

      // 默认值
      let ds = fields.value.find(e => e.value === rule.value.field);
      if (ds.default != undefined) {
        ruleData.value.value = ds.default;
        emitOnChange();
      }
    });

    // 表达式集合
    const operators = ref([]);
    const _operators = [{
      title: "等于",
      value: "Equals",
      types: ['String', 'Number', 'Date', 'Bool',, 'Boolean', 'Select']
    }, {
      title: "不等于",
      value: "NotEqual",
      types: ['String', 'Number', 'Date', 'Bool', 'Boolean', 'Select']
    }, {
      title: "包含",
      value: "Contains",
      types: ['String', 'Number']
    }, {
      title: "左包含",
      value: "StartsWith",
      types: ['String']
    }, {
      title: "右包含",
      value: "EndsWith",
      types: ['String']
    }, {
      title: "大于",
      value: "Greater",
      types: []
    }, {
      title: "大于等于",
      value: "GreaterEqual",
      types: []
    }, {
      title: "小于",
      value: "Less",
      types: []
    }, {
      title: "小于等于",
      value: "LessEqual",
      types: []
    }];
    const __returned__ = {
      props,
      fields,
      rule,
      index,
      emit,
      dataType,
      ruleData,
      configData,
      refreshOperators,
      onFieldChange,
      onOperatorsChange,
      onStringValueChange,
      onBoolValueChange,
      emitOnChange,
      operators,
      _operators,
      get JointListRules() {
        return JointListRules;
      },
      ref,
      toRefs,
      reactive,
      watch,
      onMounted,
      computed,
      nextTick
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
/**
 * 
 */
import store from '../../store';
// let name = store.state.admin.user.info.name || ''
// console.log(name)

let name = localStorage.username;
console.log(name);
let Systems = {
  path: '/Systems',
  title: '系统管理',
  children: [{
    title: "用户权限",
    children: [{
      title: "用户",
      children: [{
        path: "/BAS/User/List",
        title: '用户列表'
      }, {
        path: "/BAS/Department/List",
        title: '部门列表'
      }, {
        path: "/BAS/Menu/List",
        title: '菜单列表'
      }, {
        path: "/BAS/Role/List",
        title: '角色列表'
      }]
    }]
  }]
};
export default Systems;
export default {
  path: '/PLM',
  title: 'PLM管理',
  children: [{
    title: "产品开发",
    children: [{
      title: "产品开发",
      children: [{
        path: "/PLM/product/develop",
        title: '开发管理'
      }, {
        path: "/PLM/product/materials",
        title: '物料管理'
      }, {
        path: "/PLM/product/manuscript",
        title: '稿件管理'
      }]
    }]
  }, {
    title: "商品管理",
    children: [{
      title: "商品管理",
      children: [{
        path: "/PLM/commodity/spu",
        title: 'SPU列表'
      }, {
        path: "/PLM/commodity/sku",
        title: 'SKU列表'
      }]
    }]
  }, {
    title: "供应链管理",
    children: [{
      title: "供应链管理",
      children: [{
        path: "/PLM/supplyChain/supplier",
        title: '供应商管理'
      }]
    }]
  }, {
    title: "法规备案",
    children: [{
      title: "法规备案",
      children: [{
        path: "/PLM/law/LOAOrCfsAttest",
        title: 'LOA&CFS认证'
      }, {
        path: "/PLM/law/record",
        title: '备案管理'
      }]
    }]
  }, {
    title: "基础资料",
    children: [{
      title: "基础资料",
      children: [{
        path: "/PLM/basicData/presetData",
        title: '预设资料'
      }, {
        path: "/PLM/basicData/category",
        title: '类目管理'
      }, {
        path: "/PLM/basicData/materialGrouping",
        title: '物料分组'
      }]
    }]
  }]
};
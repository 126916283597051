import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "btns" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "class" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "list" }
const _hoisted_7 = { class: "list-group" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "ul" }
const _hoisted_10 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon")
  const _component_Dropdown = _resolveComponent("Dropdown")

  return (_openBlock(), _createBlock(_component_Dropdown, {
    ref: "refDropdown",
    trigger: "hover",
    "transfer-class-name": 'iSidebar',
    transfer: true,
    class: "iSidebar",
    placement: "bottom-end"
  }, {
    list: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.classData, (item1, index1) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("div", {
                style: {"cursor":"pointer"},
                class: _normalizeClass([$setup.selectedClass == index1 ? 'li-select' : '', "li"]),
                onClick: $event => ($setup.onClassClick(index1))
              }, _toDisplayString(item1.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_4),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item1.children, (item2, index2) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([$setup.selectedClass == index1 && $setup.selectedClassChildren == index2 ? 'childClass-select' : '', "childClass"]),
                  onClick: $event => ($setup.onClassChildrenClick(index2,index1))
                }, _toDisplayString(item2.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_5)), [
                  [_vShow, $setup.selectedClassSpread.indexOf(index1) >= 0]
                ])
              }), 256 /* UNKEYED_FRAGMENT */))
            ]))
          }), 256 /* UNKEYED_FRAGMENT */)),
          _createCommentVNode(" <div class=\"li\" v-for=\"item in classData\" :key=\"item.index\" @click=\"onClassClick(item)\">\n                        {{item.title}}\n                    </div> ")
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.listData, (item3, index3) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(item3.title), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item3.children, (li, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "ul-li",
                    onClick: $event => ($setup.onClick(li))
                  }, _toDisplayString(li.title), 9 /* TEXT, PROPS */, _hoisted_10))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          type: "md-apps",
          size: "22"
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}
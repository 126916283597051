import request from '@/plugins/request';

// 11
export function AccountLogin(data) {
  return request({
    url: '/Identity/Login',
    method: 'post',
    data
  });
}
export function AccountRegister(data) {
  return request({
    url: '/api/register',
    method: 'post',
    data
  });
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_a_config_provider = _resolveComponent("a-config-provider");
  return _openBlock(), _createBlock(_component_a_config_provider, {
    locale: $options.zhCN()
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]);
}
import { ref, reactive, onMounted, computed, nextTick } from 'vue';
export default {
  __name: 'index',
  setup(__props, {
    expose: __expose
  }) {
    __expose();
    const __returned__ = {
      ref,
      reactive,
      onMounted,
      computed,
      nextTick
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
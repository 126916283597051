export function is(val, type) {
  return toString.call(val) === `[object ${type}]`;
}
export function isString(val) {
  return is(val, 'String');
}
export function isNumber(val) {
  return is(val, 'Number');
}
export function isBoolean(val) {
  return is(val, 'Boolean');
}
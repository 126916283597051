import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'SOM-';
export default {
  path: '/SOM',
  name: 'SOM',
  redirect: {
    name: `${pre}VersionReplaceRecord`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: '/SOM/WorkOrder/List',
    name: 'SOM-WorkOrder-List',
    meta: {
      ...meta,
      title: '工单列表',
      closable: true
    },
    component: () => import('@/pages/SOM/WorkOrder/List')
  }, {
    path: '/SOM/WorkOrder/Detail',
    name: 'SOM-WorkOrder-Detail',
    meta: {
      ...meta,
      title: '工单详情',
      closable: true
    },
    component: () => import('@/pages/SOM/WorkOrder/Detail')
  }]
};
import { ref, toRefs, reactive, onMounted, computed, nextTick } from 'vue';
export default {
  __name: 'index',
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 700
    }
  },
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const props = __props;
    const {
      data,
      columns
    } = toRefs(props);

    /**
     * 单选中业务逻辑
     */
    const _selectedRow = ref({}); // 当前选中行 数据
    const _selectedRowIndex = ref(-1); // 当前选中行 行号
    const _selectedRowPrimaryKey = ref(0); // 当前选中行 主键

    const onRowClick = (row, index) => {
      refTable.value.selectAll(false); // 【多选】取消选中
      refTable.value.toggleSelect(index); // 【多选】选中当前行

      _selectedRow.value = row;
      _selectedRowIndex.value = index;
      //_selectedRowPrimaryKey.value = row.id;

      emit('on-row-selected', {
        index
      });
      emit('on-row-click', {
        row,
        index
      });
    };

    // 获取选中行 行号
    const onGetSelectedRowIndex = () => {
      return _selectedRowIndex.value;
    };
    // 获取选中行 数据
    const onGetSelectedRow = () => {
      return _selectedRow.value;
    };

    /**
     * 多选业务逻辑
     */
    const _selectionRow = ref([]); // 选择选数据
    const _selectionRowIndex = ref([]); // 选择选数据
    const _selectionRowPrimaryKey = ref([]); // 主键

    const onSelectionChange = event => {
      if (event.length === 0) {
        _selectionRow.value = [];
        _selectionRowIndex.value = [];
      } else {
        let indexs = event.map(e => {
          return data.value.findIndex(o => o.id == e.id);
        });
        _selectionRow.value = event;
        _selectionRowIndex.value = indexs;
      }
    };

    // 获取多选 行号
    const onGetSelectionRowIndex = () => {
      return _selectionRowIndex.value;
    };
    // 获取多选 数据
    const onGetSelectionRow = () => {
      return _selectionRow.value;
    };
    const selectAll = status => {
      refTable.value.selectAll(status);
    };

    /**
     * 双击行业绩逻辑
     */
    const onRowDblClick = (row, index) => {
      emit('on-row-dblclick', {
        row,
        index
      });
    };

    // table-columns
    const _columns = computed(() => {
      let ds = columns.value;
      //ds.unshift({ type: 'index', width: '50', title: '序号', className:'table-td-class', align: 'center' });
      return ds;
    });
    const refTable = ref(); // table ref

    __expose({
      onGetSelectedRowIndex,
      onGetSelectedRow,
      onGetSelectionRowIndex,
      onGetSelectionRow,
      selectAll
    });
    const __returned__ = {
      props,
      data,
      columns,
      _selectedRow,
      _selectedRowIndex,
      _selectedRowPrimaryKey,
      onRowClick,
      onGetSelectedRowIndex,
      onGetSelectedRow,
      _selectionRow,
      _selectionRowIndex,
      _selectionRowPrimaryKey,
      onSelectionChange,
      onGetSelectionRowIndex,
      onGetSelectionRow,
      selectAll,
      onRowDblClick,
      _columns,
      refTable,
      emit,
      ref,
      toRefs,
      reactive,
      onMounted,
      computed,
      nextTick
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
import { ref } from "vue";

/**
 * useDrawerTool
 */
export const useTableTool = refTable => {
  /**
   * 获取当前选择行 行号
   */
  const sysTableToolGetSelectedRowIndex = () => {
    return refTable.value.onGetSelectedRowIndex();
  };

  /**
   * 获取多选行的数据
   */
  const sysTableToolGetSelectedRow = () => {
    return refTable.value.onGetSelectionRow();
  };

  /**
   * 全选/取消全选
   */
  const sysTableToolSelectAll = status => {
    return refTable.value.selectAll(status);
  };

  /**
   * 
   */
  const sysTableToolGetSelectedRowIndexs = () => {};
  return {
    sysTableToolGetSelectedRowIndex,
    sysTableToolGetSelectedRow,
    sysTableToolSelectAll
  };
};
import { Post } from "@api/http";
import { computed, onMounted, reactive, ref, useAttrs, watch } from "vue";
import _ from "lodash";
export default {
  __name: 'Select-developer',
  props: {
    fieldNames: {
      // 自定义节点 label、value、options 的字段
      type: Object,
      default: () => {
        return {
          value: 'FCode',
          label: 'FChineseValue'
        };
      }
    },
    url: {
      type: String,
      default: () => 'PLM/Presets/ItemList'
    },
    // 请求的接口地址
    searchFieldName: {
      type: String,
      default: () => 'FChineseValue'
    } // 远程收缩的字段名
    // defaultsFirst: {type: String, default: () => ''}
  },

  emits: ['update:value', 'update:label'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const props = __props;
    const upload = ref(null);
    const attrs = useAttrs();
    const tableList = ref([]);
    const loading = ref(false);
    const value = ref('');
    const lable = ref('');
    const getList = _.debounce(async (value = '') => {
      try {
        loading.value = true;
        let {
          Items
        } = await Post(props.url, {
          "FPageSize": 20,
          "FPageIndex": 1,
          "FOrders": [],
          "FRules": [{
            "Key": props.searchFieldName,
            "LogicalOperator": "and",
            "Operator": "Contains",
            "Value": value
          }]
        });
        if (props.url === '/PLM/Approve/SummaryPageList') {
          Items.forEach((v, index) => {
            if (Items[index].FSpuNameZh) {
              Items[index].label = `${Items[index].FSpuNameEn}（${Items[index].FSpuNameZh}）`;
            } else {
              Items[index].label = `${Items[index].FSpuNameEn}`;
            }
          });
        }
        tableList.value = Items;
        loading.value = false;
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    }, 300);
    const propsRef = reactive({
      'show-search': true,
      defaultActiveFirstOption: false,
      showArrow: false,
      filterOption: false,
      'not-found-content': null,
      allowClear: true
    });
    watch(() => value.value, n => {
      emit("update:value", value.value);
      emit("update:label", lable.value);
    });
    const handleChange = (value, option) => {
      if (!value) {
        getList();
      } else {
        if (props.url === '/PLM/Approve/SummaryPageList') {
          lable.value = option.FSpuNameZh;
        } else {
          lable.value = option[props.fieldNames.label];
        }
      }
    };
    const getProps = computed(() => {
      return Object.assign(propsRef, attrs, props);
    });
    onMounted(() => {
      console.log(attrs.label);
      getList(attrs.label);
    });
    __expose({
      getList
    });
    const __returned__ = {
      upload,
      attrs,
      tableList,
      loading,
      value,
      lable,
      getList,
      propsRef,
      props,
      emit,
      handleChange,
      getProps,
      get Post() {
        return Post;
      },
      computed,
      onMounted,
      reactive,
      ref,
      useAttrs,
      watch,
      get _() {
        return _;
      }
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};
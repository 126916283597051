import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "btn-group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button");
  const _component_Drawer = _resolveComponent("Drawer");
  return _openBlock(), _createBlock(_component_Drawer, {
    width: $props.width,
    "mask-closable": $props.maskClosable,
    closable: $props.closable,
    "model-value": $props.modelValue,
    onOnVisibleChange: $setup.onVisibleChange
  }, _createSlots({
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 2 /* DYNAMIC */
  }, [$props.header ? {
    name: "header",
    fn: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Button, {
      class: "ivu-mr-8",
      onClick: $setup.onCancel
    }, {
      default: _withCtx(() => [_createTextVNode("取消")]),
      _: 1 /* STABLE */
    }), $props.isSave == true ? (_openBlock(), _createBlock(_component_Button, {
      key: 0,
      class: "ivu-mr-8",
      type: "primary",
      onClick: $setup.onSave
    }, {
      default: _withCtx(() => [_createTextVNode("保存")]),
      _: 1 /* STABLE */
    })) : _createCommentVNode("v-if", true), $props.isCreate == true ? (_openBlock(), _createBlock(_component_Button, {
      key: 1,
      class: "ivu-mr-8",
      type: "info",
      onClick: $setup.onCreate
    }, {
      default: _withCtx(() => [_createTextVNode("创建")]),
      _: 1 /* STABLE */
    })) : _createCommentVNode("v-if", true)]), _createCommentVNode(" <div class=\"title\">{{title}}</div> ")])]),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["width", "mask-closable", "closable", "model-value"]);
}
import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_select = _resolveComponent("a-select");
  return _openBlock(), _createElementBlock("div", null, [_createCommentVNode("    <Select ref=\"upload\" v-model=\"value\" v-bind=\"getProps\">"), _createCommentVNode("      <Option v-for=\"item in tableList\" :value=\"item[optionConfig.valueKey]\" :key=\"item[optionConfig.valueKey]\">{{ item[optionConfig.labelKey] }}</Option>"), _createCommentVNode("    </Select>"), _createVNode(_component_a_select, _mergeProps({
    value: $setup.value,
    "onUpdate:value": _cache[0] || (_cache[0] = $event => $setup.value = $event),
    options: $setup.tableList,
    fieldNames: $props.fieldNames,
    onSearch: _cache[1] || (_cache[1] = v => $setup.getList(v)),
    onChange: $setup.handleChange
  }, $setup.getProps), null, 16 /* FULL_PROPS */, ["value", "options", "fieldNames"])]);
}
export default {
  path: '/monitor',
  title: '监控管理',
  children: [{
    title: "平台监控",
    children: [{
      title: "平台监控",
      children: [{
        path: "/monitor/platform/stock",
        title: '库存监控'
      }]
    }]
  }]
};
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1651052284018");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1651052284018");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1651052284018");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"i-icon\";\n\n  /* Project id 1125611 */\n\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n\n.i-icon {\n  font-family: \"i-icon\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.i-icon-i18n:before {\n  content: \"\\e8e9\";\n}\n\n.i-icon-refresh:before {\n  content: \"\\e7d1\";\n}\n\n.i-icon-record:before {\n  content: \"\\e61f\";\n}\n\n.i-icon-notification:before {\n  content: \"\\e69d\";\n}\n\n.i-icon-full-screen:before {\n  content: \"\\e743\";\n}\n\n.i-icon-exit-full-screen:before {\n  content: \"\\e657\";\n}\n\n.i-icon-menu-unfold:before {\n  content: \"\\e68b\";\n}\n\n.i-icon-menu-fold:before {\n  content: \"\\e600\";\n}\n", ""]);
// Exports
module.exports = exports;

/**
 * 路由拦截
 * 权限验证
 */

import Setting from "@/setting";
import ViewUIPlus, { Message } from "view-ui-plus";
import util from "@/libs/util";
import store from "@/store";
import router from "@/router";
import { getToken, removeToken } from "@/utils/auth";
const whiteList = ['/login', '/auth-redirect']; // no redirect whitelist
import { useUserStore } from "@/storePinia/user";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "@/App.vue";
let userStore = null;
let isAddRoute = false;
const app = createApp(App);

// 跳转前
router.beforeEach(async (to, from, next) => {
  // if (Setting.showProgressBar) ViewUIPlus.LoadingBar.start();
  // 是否有token
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({
        path: '/'
      });
    } else {
      if (userStore === null) {
        userStore = useUserStore();
      }
      // console.log(userStore)

      // determine whether the user has obtained his permission roles through getInfo
      // const hasRoles = userStore.roles && userStore.roles.length > 0
      const hasRoles = userStore.userInfo.FId;
      if (hasRoles) {
        console.log(from);
        console.log(to);
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']

          const res = await userStore.getUserInfo();

          // generate accessible routes map based on roles
          // const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          // dynamically add accessible routes
          userStore.routerList.forEach(v => {
            console.log('添加的路由');
            console.log(v);
            router.addRoute(v);
          });
          router.addRoute({
            path: '/:pathMatch(.*)',
            name: '404',
            meta: {
              title: '404'
            },
            component: () => import('@/pages/system/error/404')
          });

          // for (let i = 0; i < userStore.routerList.length; i++) {
          //     router.addRoute(userStore.routerList[i])
          // }

          next({
            path: `${to.fullPath}`
          });

          // next({...to})

          // 确保addRoutes完整的破解方法
          // 设置replace:true，这样导航就不会留下历史记录
        } catch (error) {
          console.log('报错啦');
          console.log(error);
          // remove token and go to login page to re-login
          // await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error');
          // next(`/login?redirect=${to.fullPath}`)
          removeToken();
          next({
            name: 'login',
            query: {
              redirect: to.fullPath
            }
          });
        }
      }
    }
  } else {
    /* has no token*/
    console.log('我这李');
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.fullPath}`);
    }
  }

  // 判断是否需要登录才可以进入
  // if (to.matched.some(_ => _.meta.auth)) {
  //     // 这里依据 token 判断是否登录，可视情况修改
  //     const token = util.cookies.get('token');
  //
  //     if (token && token !== 'undefined') {
  //         next();
  //     } else {
  //         // 没有登录的时候跳转到登录界面
  //         // 携带上登陆成功之后需要跳转的页面完整路径
  //         next({
  //             name: 'login',
  //             query: {
  //                 redirect: to.fullPath
  //             }
  //         });
  //     }
  // } else {
  //     // 不需要身份校验 直接通过
  //     next();
  // }
});

// 跳转后
router.afterEach(to => {
  // if (Setting.showProgressBar) ViewUIPlus.LoadingBar.finish();
  console.log('meta' in to);
  // 多页控制 打开新的页面
  // if (!('meta' in to) || (to.meta && !('tabs' in to.meta)) || (to.meta && to.meta.tabs)) {
  //     store.dispatch('admin/page/open', to);
  // }
  store.dispatch('admin/page/open', to);
  console.log(to.path);
  // store.commit('admin/menu/setActivePath', to.path);
  // 更改标题
  util.title({
    title: to.meta.title
  });
  // 返回页面顶端
  window.scrollTo(0, 0);
});
import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'sales-';
export default {
  path: '/sales',
  name: 'sales',
  redirect: {
    name: `${pre}VersionReplaceRecord`
  },
  meta,
  component: BasicLayout,
  children: [
  // MaBangVersionReplace
  {
    path: '/Sales/MaBangVersionReplace/ReplaceLog/List',
    name: 'Sales-MaBangVersionReplace-ReplaceLog-List',
    meta: {
      ...meta,
      title: '物料版本替换记录',
      closable: true
    },
    component: () => import('@/pages/SAL/MaBangVersionReplace/ReplaceLog/List')
  }, {
    path: '/Sales/MaBangVersionReplace/ShopConfig/List',
    name: 'Sales-MaBangVersionReplace-ShopConfig-List',
    meta: {
      ...meta,
      title: '平台店铺替换开启状态',
      closable: true
    },
    component: () => import('@/pages/SAL/MaBangVersionReplace/ShopConfig/List')
  },
  // OmsBusinessData
  {
    path: '/SAL/OmsBusinessData/MaBangSaleOrder/List',
    name: 'SAL-OmsBusinessData-MaBangSaleOrder-List',
    meta: {
      ...meta,
      title: 'OMS马帮销售单',
      closable: true
    },
    component: () => import('@/pages/SAL/OmsBusinessData/MaBangSaleOrder/List')
  }, {
    path: '/SAL/OmsBusinessData/MaBangReturnOrder/List',
    name: 'SAL-OmsBusinessData-MaBangReturnOrder-List',
    meta: {
      ...meta,
      title: 'OMS马帮退货单',
      closable: true
    },
    component: () => import('@/pages/SAL/OmsBusinessData/MaBangReturnOrder/List')
  }, {
    path: '/SAL/OmsBusinessData/WangDianTongSaleOrder/List',
    name: 'SAL-OmsBusinessData-WangDianTongSaleOrder-List',
    meta: {
      ...meta,
      title: 'OMS旺店通销售单',
      closable: true
    },
    component: () => import('@/pages/SAL/OmsBusinessData/WangDianTongSaleOrder/List')
  }, {
    path: '/SAL/OmsBusinessData/WangDianTongReturnOrder/List',
    name: 'SAL-OmsBusinessData-WangDianTongReturnOrder-List',
    meta: {
      ...meta,
      title: 'OMS旺店通退货单',
      closable: true
    },
    component: () => import('@/pages/SAL/OmsBusinessData/WangDianTongReturnOrder/List')
  },
  // PlatformBusinessData
  {
    path: '/SAL/PlatformBusinessData/MaBangSaleOrder/List',
    name: 'SAL-PlatformBusinessData-MaBangSaleOrder-List',
    meta: {
      ...meta,
      title: '平台马帮销售单',
      closable: true
    },
    component: () => import('@/pages/SAL/PlatformBusinessData/MaBangSaleOrder/List')
  }, {
    path: '/SAL/PlatformBusinessData/MaBangReturnOrder/List',
    name: 'SAL-PlatformBusinessData-MaBangReturnOrder-List',
    meta: {
      ...meta,
      title: '平台马帮退货单',
      closable: true
    },
    component: () => import('@/pages/SAL/PlatformBusinessData/MaBangReturnOrder/List')
  }, {
    path: '/SAL/PlatformBusinessData/WangDianTongSaleOrder/List',
    name: 'SAL-PlatformBusinessData-WangDianTongSaleOrder-List',
    meta: {
      ...meta,
      title: '平台旺店通销售单',
      closable: true
    },
    component: () => import('@/pages/SAL/PlatformBusinessData/WangDianTongSaleOrder/List')
  }, {
    path: '/SAL/PlatformBusinessData/WangDianTongReturnOrder/List',
    name: 'SAL-PlatformBusinessData-WangDianTongReturnOrder-List',
    meta: {
      ...meta,
      title: '平台旺店通退货单',
      closable: true
    },
    component: () => import('@/pages/SAL/PlatformBusinessData/WangDianTongReturnOrder/List')
  }]
};
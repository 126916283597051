export const useVaildator = () => {
  const method = {
    selectVaildator: (rule, value, callback) => {
      console.log(value);
      if (value) {
        callback();
      } else {
        callback(new Error('请选择'));
      }
    }
  };
  return {
    method
  };
};
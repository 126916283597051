export default {
  path: '/Fin',
  title: '财务管理',
  children: [{
    title: "平台对账核销",
    children: [{
      title: "平台对账核销",
      children: [{
        path: "/FIN/WriteOff/Warehouse/List",
        title: 'AC出库单列表'
      }, {
        path: "/FIN/WriteOff/refundNote/List",
        title: 'AC退货单列表'
      }, {
        path: "/FIN/WriteOff/receipt/List",
        title: 'AC收款单列表'
      }, {
        path: "/FIN/WriteOff/receivable/List",
        title: 'AC应收调整单'
      }, {
        path: "/FIN/WriteOff/record/List",
        title: 'AC核销记录'
      }, {
        path: "/Sales/PlatformMaBangSaleOrder/List",
        title: '马帮销售单'
      }, {
        path: "/Sales/PlatformMaBangReturnOrder/List",
        title: '马帮退货单'
      }, {
        path: "/Sales/PlatformWangDianTongSaleOrder/List",
        title: '旺店通销售单'
      }, {
        path: "/Sales/PlatformWangDianTongReturnOrder/List",
        title: '旺店通退货单'
      }
      // {
      //     path: "/Sales/MaBangVersionReplaceLog/List",
      //     title: '下载管理'
      // },
      ]
    }, {
      title: "配置参数",
      children: [{
        path: "/FIN/Configuration/PlatformShop/List",
        title: '店铺关联配置'
      }, {
        path: "/MDM/PlatformStore/List",
        title: '平台店铺列表'
      }]
    }]
  }]
};
import BasicLayout from '@/layouts/basic-layout';
const meta = {
  auth: true
};
const pre = 'EHR-';
export default {
  path: '/EHR',
  name: 'EHR',
  redirect: {
    name: `${pre}VersionReplaceRecord`
  },
  meta,
  component: BasicLayout,
  children: [{
    path: '/EHR/Base/Staff/List',
    name: 'EHR-Base-Staff-List',
    meta: {
      ...meta,
      title: '职员列表',
      closable: true
    },
    component: () => import('@/pages/EHR/Base/Staff/List')
  }, {
    path: '/EHR/Base/Department/List',
    name: 'EHR-Base-Department-List',
    meta: {
      ...meta,
      title: '部门列表',
      closable: true
    },
    component: () => import('@/pages/EHR/Base/Department/List')
  }]
};